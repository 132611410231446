<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  size="12"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Recovery_phone')">
                <el-input
                  :maxlength="11"
                  v-model.trim="formInline.mobile"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Recovery_number')">
                <el-input
                  :maxlength="30"
                  v-model.trim="formInline.arrearsCode"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Dialing_person')">
                <el-input v-model.trim="formInline.dialtorName" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Recovery_time')">
                <el-date-picker
                  v-model="rangeDate"
                  @change="changeTime"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  :clearable="false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="-"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>

          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                @exportFile="handleExportFile"
                :exportData="exportData"
              ></exportFile>
            </div>
          </div>
          <!-- <el-button type="primary" icon="el-icon-delete" @click="resetForm()"
            >{{ $t('button.reset') }}</el-button
          > -->
          <!-- <el-button type="primary" @click="exportFile">{{ $t('button.export') }}</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";
const now = new Date();
const start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
start.setHours(0);
start.setMinutes(0);
start.setSeconds(0);
now.setHours(23);
now.setMinutes(59);
now.setSeconds(59);
export default {
  name: "staggeredOrder",
  data() {
    return {
      plateNumber: "",
      rowData: {},
      temParkData: {},
      temParkData1: {},
      infoSize: 4,
      exportData: {},
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      modelvalue1: "",
      rule: {},
      tableCols: [
        {
          prop: "mobile",
          label: "追缴电话",
          width: "",
        },
        {
          prop: "channelDesc",
          label: "渠道",
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          formatter: (row) => {
            return row.plateNumber && row.plateNumber.indexOf("无") != -1
              ? "无牌车"
              : row.plateNumber;
          },
          width: "",
        },
        {
          prop: "money",
          label: "追缴金额",
          width: "",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
        {
          prop: "arrearsTime",
          label: "追缴时间",
        },
        {
          prop: "phoneTypeDesc",
          label: "类型",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "dialtorName",
          label: "拨打人",
        },
        {
          prop: "operatorName",
          label: "追缴负责人",
          width: "",
        },
        {
          prop: "arrearsCode",
          label: "追缴编号",
          width: "",
        },
      ],

      tableData: [],
      pickerOptions: {
        disabledDate: (val) => {
          // const now = new Date().getTime();
          return val.getTime() > now;
        },
      },
      rangeDate: [dateFormat(start), dateFormat(now)],
      formInline: {
        carId: "",
        mobile: "",
        arrearsCode: "",
        dialtorName: "",
        startArrearsTime: dateFormat(start),
        endArrearsTime: dateFormat(now),
        name: "电话记录订单",
      },
    };
  },
  methods: {
    changeTime(rangeDate) {
      if (rangeDate) {
        this.formInline.startArrearsTime = rangeDate[0];
        this.formInline.endArrearsTime = rangeDate[1];
      } else {
        this.formInline.startArrearsTime = "";
        this.formInline.endArrearsTime = "";
      }
    },
    // 时间判断
    showLog() {
      if (this.rangeDate) {
        if (this.rangeDate[0] && this.rangeDate[1]) {
          let time = new Date(this.rangeDate[1]) - new Date(this.rangeDate[0]);
          time = time / (24 * 60 * 60 * 1000);
          if (time > 366 && this.formInline.carId == "") {
            this.$alert("查询时间不能超过366天");
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    /* 车牌号 */
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          res.value.forEach((v) => {
            v.value = v.plateNumber;
          });
          cb(res.value);
        }
      });
    },
    handleSelect(item) {
      this.formInline.carId = item.carId;
    },
    searchData() {
      let flag = this.showLog();
      if (flag) {
        this.$axios
          .get("/acb/2.0/arrears/queryMobileArrearsRecord", {
            data: {
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              // startArrearsTime: this.rangeDate[0],
              // endArrearsTime: this.rangeDate[1],
              ...this.formInline,
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.state == 0) {
              this.tableData = setIndex(this.pageNum, res.value.list);
              this.total = res.value.total * 1 || 0;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
      if (!this.formInline.parkId) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
    },
    // 搜索
    searParkRecordList() {
      // console.log(this.formInline);
      this.loading = true;
      if (this.formInline.parkId == "") {
        this.formInline.parkName = "";
      }
      this.searchData();
    },
    handleExportFile() {
      // let data = JSON.parse(JSON.stringify(this.formInline));
      let data = {
        ...this.formInline,
        // startArrearsTime: this.rangeDate[0],
        // endArrearsTime: this.rangeDate[1],
        arrearsType: 2,
      };
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/arrears/exportArrearsRecord",
        methods: "post",
        data: data,
      };
    },
    resetForm() {
      // this.formInline = {
      //   carId: '',
      //   mobile: '',
      //   parkId: '',
      //   payType: ''
      // }
      this.formInline.carId = "";
      this.formInline.mobile = "";
      this.formInline.parkId = "";
      this.formInline.payType = "";
      this.formInline.parkName = "";
      this.modelvalue = "";
      this.rangeDate = [dateFormat(start), dateFormat(now)];
    },
  },
  components: {
    exportFile,
  },
  created() {
    if (this.$route.params.tradeNo) {
      this.formInline.tradeNo = this.$route.params.tradeNo;
      this.rangeDate = [];
    }
  },
  mounted() {
    // console.log("当前权限为", this.$route.meta.authority.button);
    this.pageNum = 1;
    this.pageSize = 15;
    this.searchData();
    this.$nextTick(() => {
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/arrears/exportArrearsRecord",
        methods: "post",
        data: this.formInline,
      };
    });
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
